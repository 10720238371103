import React from "react";
import { Helmet } from "react-helmet-async";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Backdrop,
  Card,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { getDashboardData } from "../services/dashboard.service";
import i18n from "src/i18n";
import config from "src/config";
import airports from "../images/airports.svg";
import offersImage from "../images/offersImage.png";
import tasks from "../images/tasks.svg";
import trainees from "../images/trainees.svg";
import handrailsImage from "../images/handrails.png";
import courses from "../images/courses.svg";
import { useNavigate, useParams } from "react-router-dom";
import { convertMessageCodeToMessage } from "../utils/messageCodeToMessage";
import Swal from "sweetalert2";
import HandrailsTable from "../SharedComponent/HandrailsTable";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  deleteHandrail,
  editHandrail,
  getHandrailList,
} from "../services/handrail.service";
import { getAirportList } from "../services/airport.service";
import { getOfferList } from "../services/offer.service";
import { debounce } from "../utils/debounce";
import Iconify from "../components/iconify/Iconify";

const TABLE_HEAD = [
  {
    id: "airport",
    label: i18n.t("airport"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "mandate_number",
    label: i18n.t("mandate_number"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "date",
    label: i18n.t("date"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "hour",
    label: i18n.t("hour"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "trades",
    label: i18n.t("trades"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "information",
    label: i18n.t("information"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "importance",
    label: i18n.t("importance"),
    alignCenter: true,
    sortable: true,
  },
  { id: "" },
];
const StyledCard = styled(Card)(({ theme }) => ({
  minHeight:'8rem',
  height: "auto",
  cursor: "pointer",
  backgroundColor: "#99919c",
  display: "flex",
  alignItems: "center",
  "&:hover": {
    boxShadow: "10px 10px 5px lightblue",
  },
}));

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dashboardDetails: {},
      handrailDetails: {
        handrails: [],
        handrail: {},
        open: null,
        page: 1,
        search: "",
        limit: 100,
        skip: 0,
        total_handrails: 0,
        expanded: false,
        tableLoader: false,
        filters: {},
        filtersOptions: {},
        orderBy: { sort_key: "", sort_order: true },
        showIsOpenDropDown: false,
        deleteHandrailPopUp: false,
      },
    };
    this.styles = {
      mainLabelStyles: {
        color: "white",
        margin: "5px 20px",
        fontSize: "18px",
      },
      labelStyles: {
        color: "white",
        margin: "10px 20px",
        fontSize: "15px",
        wordWrap: "break-word",
        width: "150px",
      },
      labelStylesValue: {
        color: "white",
        margin: "10px 20px",
        fontSize: "15px",
      },
      cardStyles: {
        marginBottom: "10px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        height:"100%"
      },
      alignStyles:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent:'space-between',
        minHeight: '16vh',
        margin: '0',
        padding:'0'
      }
    };
  }
  async componentDidMount() {
    this.getHandrails();
    this.getDashboardDetails();
    await this.getAirportsForFilter();
    this.getOffersForFilter();
  }

  async getDashboardDetails() {
    let dashboardData = await getDashboardData();
    if (dashboardData.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: dashboardData?.error_code
          ? i18n.t(convertMessageCodeToMessage(dashboardData?.error_code))
          : dashboardData?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    this.setState({
      dashboardDetails: dashboardData.dashboard,
    });
  }

  async getHandrails(action) {
    this.setState({
      handrailDetails: {
        ...this.state?.handrailDetails,
        tableLoader: true,
      },
    });

    let query = {
      limit: this.state?.handrailDetails.limit,
      skip: this.state?.handrailDetails.skip,
      last_fortnight:true,
      importance:'high,medium'
    };
    if (this.state?.handrailDetails?.orderBy?.sort_key) {
      query["sort_key"] = this.state?.handrailDetails?.orderBy?.sort_key,
      query["sort_order"] = this.state?.handrailDetails?.orderBy?.sort_order
          ? "ASC"
          : "DESC";
    }
    if (action === "filters") {
      query.skip = 0;
      await this.setState({
        handrailDetails: {
          ...this.state?.handrailDetails,
          skip: 0,
          page: 1,
        },
      });
    }
    if(this.state?.handrailDetails?.filters){
    Object?.keys(this.state?.handrailDetails?.filters)?.map((key) => {
      if (this.state?.handrailDetails?.filters[key] !== "") {
        query[key] = this.state?.handrailDetails?.filters[key]?.value;
      }
    });
  }
    if (this.state?.handrailDetails?.search) {
      query.search = this.state?.handrailDetails.search;
    }
    let handrails = await getHandrailList(query);
    if (handrails.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: handrails?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(handrails?.error_code))
          : handrails?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        handrailDetails: {
          ...this.state?.handrailDetails,
          tableLoader: false,
        },
      });
      return;
    }
    await this.setState({
      handrailDetails: {
        ...this.state?.handrailDetails,
        tableLoader: false,
        handrails: handrails.handrails,
        total_handrails: handrails.count,
      },
    });
  }
  async getAirportsForFilter() {
    let query = {
      minimal: true,
    };

    let response = await getAirportList(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let airportOptions = response?.map((airport) => {
      return { label: airport?.name, value: airport?._id };
    });

    this.setState({
      handrailDetails: {
        ...this.state?.handrailDetails,
        filtersOptions: {
          ...this.state?.handrailDetails.filtersOptions,
          airportOptions,
        },
      },
    });
  }
  async getOffersForFilter() {
    let query = {
      minimal: true,
    };

    let response = await getOfferList(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let offerOptions = response?.offers?.map((offer) => {
      return { label: offer?.mandate_number, value: offer?._id };
    });

    await this.setState({
      handrailDetails: {
        ...this.state?.handrailDetails,
        filtersOptions: {
          ...this.state?.handrailDetails.filtersOptions,
          offerOptions,
        },
      },
    });
  }

  handleExpandClick = () => {
    this.setState({
      handrailDetails: {
        ...this.state?.handrailDetails,
        expanded: !this.state?.handrailDetails.expanded,
      },
    });
  };

  handleOpenMenu = (event) => {
    this.setState({
      handrailDetails: {
        ...this.state?.handrailDetails,
        open: event.currentTarget,
      },
    });
  };

  handleCloseMenu = () => {
    this.setState({
      handrailDetails: {
        ...this.state?.handrailDetails,
        open: null,
      },
    });
  };
  getHandrailDataOnchange = debounce(() => {
    this.getHandrails("filters");
  }, 500);

  async updateHandrailData(payload, handrailId, backUpData) {
    let response = await editHandrail(handrailId, payload);
    if (response?.error) {
      let tempData = this.state?.handrailDetails.handrails;
      tempData[backUpData?.rowIndex] = backUpData?.rowData;
      this.setState({
        handrailDetails: {
          ...this.state?.handrailDetails,
          handrails: tempData,
        },
      });
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response?.handrail) {
      // Swal toast
      Swal.fire({
        toast: true,
        position: "bottom-end",
        icon: "success",
        title: i18n.t("success"),
        text: i18n.t("handrail_updated_successfully"),
        showConfirmButton: false,
        timer: config.toastDelay,
        timerProgressBar: true,
        customClass: {
          container: "my-swal",
        },
      });
    }
  }
  async handleDeleteHandrail(handrailId) {
    this.setState({
      loading: true,
    });
    let response = await deleteHandrail(handrailId);

    if (response?.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response) {
      await this.getHandrails();
      this.getDashboardDetails();
      this.setState({
        loading: false,
      });
    }
  }

  getHandrailsDataOnchange = debounce(() => {
    this.getHandrails("filters");
  }, 500);

  handleTableAction = async (action, data, rowData, key, rowIndex) => {
    if (action === "pagination") {
      await this.setState({
        handrailDetails: {
          ...this.state?.handrailDetails,
          skip: (data - 1) * this.state?.handrailDetails.limit,
          page: data,
        },
      });
      this.getHandrails();
    } else if (action === "limit") {
      await this.setState({
        handrailDetails: {
          ...this.state?.handrailDetails,
          limit: data,
        },
      });
      this.getHandrails();
    } else if (action === "popOverAction") {
      this.setState({
        handrailDetails: {
          ...this.state?.handrailDetails,
          handrail: rowData,
          open: data?.currentTarget,
        },
      });
    } else if (action === "sort") {
      /**These keys are inside airport key from BE,
       * the sort key for these are like : airport.[keyName]
       */
      if (config?.airportJsonKeys.includes(data.toLowerCase())) {
        await this.setState({
          handrailDetails: {
          ...this.state?.handrailDetails,
          orderBy: {
            sort_key:
              data === "airport"
                ? `airport_name`
                : `airport_${data.toLowerCase()}`,
            sort_order: !this.state?.handrailDetails.orderBy?.sort_order,
          },
        }
        });
      } else {
        await this.setState({
          handrailDetails: {
          ...this.state?.handrailDetails,
          orderBy: {
            sort_key: data,
            sort_order: !this.state?.handrailDetails.orderBy?.sort_order,
          },
        }
        });
      }
      this.getHandrails();
    } else if (action === "closeMenu") {
      this.handleCloseMenu();
    } else if (action === "update") {
      if (rowData[key] === data) return;
      let backUpData = { rowData, rowIndex };
      let payload = { ...rowData };
      payload[key] = data;
      let tempData = this.state?.handrailDetails.handrails;
      tempData[rowIndex] = { ...payload };
      payload["airport"] = payload["airport"]?._id;
      let temp_btee_sa_employees = rowData?.btee_sa_employees?.map(
        (data) => data?._id
      );
      let temp_airport_employees = rowData?.airport_employees?.map(
        (data) => data?._id
      );
      payload["btee_sa_employees"] = temp_btee_sa_employees;
      payload["airport_employees"] = temp_airport_employees;
      payload["airport"] = rowData?.airport?._id;
      payload["offer_number"] = rowData?.offer_number?._id;
      payload["trades"] = rowData?.trades;
      this.setState({
        handrailDetails: {
          ...this.state?.handrailDetails,
          handrails: tempData,
        },
      });
      this.updateHandrailData(payload, payload?._id, backUpData);
    } else if (action === "deleteHandrail") {
      this.handleCloseMenu();
      await this.setState({
        handrailDetails: {
          ...this.state?.handrailDetails,
          deleteHandrailPopUp: true,
        },
      });
    } else if (action === "closeConfirmationPopUp") {
      this.setState({
        handrailDetails: {
          ...this.state?.handrailDetails,
          deleteHandrailPopUp: false,
        },
      });
    } else if (action === "proceed") {
      this.setState({
        handrailDetails: {
          ...this.state?.handrailDetails,
          deleteHandrailPopUp: false,
        },
      });
      this.handleDeleteHandrail(this.state?.handrailDetails?.handrail._id);
    }
  };
  render() {
    return (
      <>
        <Helmet>
          <title>
            {" "}
            {i18n.t("dashboard")} | {config.APPLICATION_NAME}{" "}
          </title>
        </Helmet>

        <Container maxWidth="xxl">
          <Typography className='pageHeader' style={{marginTop:'-65px'}} variant="h4" sx={{ mb: 5 }}>
            {i18n.t("welcome")}
          </Typography>
          <Grid
            container
            spacing={3}
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center"
            }}
          >
            <Grid item xs={12} sm={6} md={3}>
              <StyledCard
                onClick={() => {
                  this.props.navigate("/airports/view");
                }}
              >
                <Grid container spacing={2} style={{ padding: "0",margin:'0',height:"100%" ,minHeight: '7rem'}}>
                  <Grid item xs={8} 
                     style={this.styles.alignStyles}
                  >
                    <Typography
                      gutterBottom
                      style={this.styles?.mainLabelStyles}
                      component="div"
                    >
                      {i18n.t("airports")}
                    </Typography>
                    <Typography
                      gutterBottom
                      style={this.styles?.mainLabelStyles}
                      component="div"
                    >
                      {this.state?.dashboardDetails?.airports?.total}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    justifyContent="center"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <img
                      src={airports}
                      alt="airportImage"
                      style={{ maxHeight: "6rem" }}
                    />
                  </Grid>
                </Grid>
              </StyledCard>
            </Grid>
             <Grid item xs={12} sm={6} md={3}>
              <StyledCard
              style={{ backgroundColor: "#5888e8" }}
                onClick={() => {
                  this.props.navigate("/offers/view");
                }}
              >
                <Grid container spacing={2} style={{ padding: "0",margin:'0',height:"100%" ,minHeight: '7rem'}}>
                  <Grid item xs={8}
                  style={this.styles.alignStyles}>
                    <Typography
                      gutterBottom
                      style={this.styles?.mainLabelStyles}
                      component="div"
                    >
                      {i18n.t("complete_dashboard")}
                    </Typography>
                    <Typography
                      gutterBottom
                      style={this.styles?.mainLabelStyles}
                      component="div"
                    >
                      { this.state?.dashboardDetails?.offers?.status?.in_progress}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    justifyContent="center"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <img
                      src={offersImage}
                      alt="airportImage"
                      style={{ maxHeight: "6rem" }}
                    />
                  </Grid>
                </Grid>
              </StyledCard>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StyledCard
              style={{ backgroundColor: "#e16f7f" }}
                onClick={() => {
                  this.props.navigate("/tasks/view");
                }}
              >
                <Grid container spacing={2}style={{ padding: "0",margin:'0',height:"100%" ,minHeight: '7rem'}}>
                  <Grid item xs={8} style={this.styles.alignStyles}>
                    <Typography
                      gutterBottom
                      style={this.styles.mainLabelStyles}
                      component="div"
                    >
                      {i18n.t("tasks")}
                    </Typography>
                    <Typography
                      gutterBottom
                      style={this.styles.mainLabelStyles}
                      component="div"
                    >
                      {this.state?.dashboardDetails?.tasks?.total}
                    </Typography>
                  </Grid>

                  <Grid item xs={3} style={{ marginTop: "10px" }}>
                    <img
                      src={tasks}
                      alt="tasksImage"
                      style={{ maxHeight: "6rem" }}
                    />
                  </Grid>
                </Grid>
              </StyledCard>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StyledCard
                style={{ backgroundColor: "#ed1872" }}
                onClick={() => {
                  this.props.navigate("/handrails/view");
                }}
              >
                <Grid container spacing={2} style={{ padding: "0",margin:'0',height:"100%" ,minHeight: '7rem'}}>
                  <Grid item xs={8} style={this.styles.alignStyles}>
                    <Typography
                      gutterBottom
                      style={this.styles.mainLabelStyles}
                      component="div"
                    >
                      {i18n.t("handrails")}
                    </Typography>
                    <Typography
                      gutterBottom
                      style={this.styles.mainLabelStyles}
                      component="div"
                    >
                      {this.state?.dashboardDetails?.handrails?.total}
                    </Typography>
                  </Grid>

                  <Grid item xs={3} style={{ marginTop: "10px" }}>
                    <img
                      src={handrailsImage}
                      alt="handrailsImage"
                      style={{ maxHeight: "6rem" }}
                    />
                  </Grid>
                </Grid>
              </StyledCard>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StyledCard
                style={{ backgroundColor: "#98d6e2" }}
                onClick={() => {
                  this.props.navigate("/airtrace/view/trainees");
                }}
              >
                <Grid container spacing={2} style={{ padding: "0",margin:'0',height:"100%" ,minHeight: '7rem'}}>
                  <Grid item xs={8} style={this.styles.alignStyles}>
                    <Typography
                      gutterBottom
                      style={this.styles.mainLabelStyles}
                      component="div"
                    >
                      {i18n.t("trainees")}
                    </Typography>
                    <Typography
                      gutterBottom
                      style={this.styles.mainLabelStyles}
                      component="div"
                    >
                      {this.state?.dashboardDetails?.trainees?.total}
                    </Typography>
                  </Grid>

                  <Grid item xs={3} style={{ marginTop: "10px" }}>
                    <img
                      src={trainees}
                      alt="traineesImage"
                      style={{ maxHeight: "6rem" }}
                    />
                  </Grid>
                </Grid>
              </StyledCard>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <StyledCard
                style={{ backgroundColor: "#a8acb0" }}
                onClick={() => {
                  this.props.navigate("/airtrace/view/courses");
                }}
              >
                <Grid container spacing={2} style={{ padding: "0",margin:'0',height:"100%" ,minHeight: '7rem'}}>
                  <Grid item xs={8} style={this.styles.alignStyles}>
                    <Typography
                      gutterBottom
                      style={this.styles.mainLabelStyles}
                      component="div"
                    >
                      {i18n.t("courses")}
                    </Typography>
                    <Typography
                      gutterBottom
                      style={this.styles.mainLabelStyles}
                      component="div"
                    >
                      {this.state?.dashboardDetails?.courses?.total}
                    </Typography>
                  </Grid>

                  <Grid item xs={3} style={{ marginTop: "10px" }}>
                    <img
                      src={courses}
                      alt="coursesImage"
                      style={{ maxHeight: "6rem" }}
                    />
                  </Grid>
                </Grid>
              </StyledCard>
            </Grid>
          </Grid>
          <div>
            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              sx={{
                background: "white",
                marginBottom:'10px'
              }}
            >
              <AccordionSummary
                style={{ background: "white" }}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Input
                  autoFocus
                  fullWidth
                  disableUnderline
                  placeholder="Search…"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify
                        icon="eva:search-fill"
                        sx={{ color: "text.disabled", width: 20, height: 20 }}
                      />
                    </InputAdornment>
                  }
                  onChange={(e) => {
                    this.setState({
                      handrailDetails: {
                        ...this.state?.handrailDetails,
                        tableLoader: true,
                        search: e?.target?.value,
                      },
                    });
                    this.getHandrailDataOnchange();
                  }}
                  sx={{ mr: 1, fontWeight: "fontWeightBold" }}
                />
                <IconButton
                  onClick={() => {
                    this.handleExpandClick();
                  }}
                >
                  <FilterAltIcon />
                </IconButton>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Grid container spacing={3} mb={2}>
                    <Grid item xs={12} md={4}>
                      <Stack spacing={1}>
                        <Autocomplete
                         noOptionsText={i18n.t('no_options')}
                          disablePortal
                          options={
                            !this.state?.handrailDetails?.filtersOptions
                              ?.airportOptions
                              ? [{ label: "Loading...", id: 0 }]
                              : this.state?.handrailDetails?.filtersOptions
                                  ?.airportOptions
                          }
                          onChange={async (option, value) => {
                            await this.setState({
                              handrailDetails: {
                                ...this.state?.handrailDetails,
                                tableLoader: true,
                                filters: {
                                  ...this.state?.handrailDetails?.filters,
                                  airport: {
                                    label: value?.label,
                                    value: value ? value?.value : "",
                                  },
                                },
                              },
                            });
                            this.getHandrailsDataOnchange();
                          }}
                          value={
                            this.state?.handrailDetails?.filters?.airport
                              ?.label || ""
                          }
                          renderInput={(params) => (
                            <TextField {...params} label={i18n.t("airport")} />
                          )}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack spacing={1}>
                        <Autocomplete
                         noOptionsText={i18n.t('no_options')}
                          required
                          disablePortal
                          options={
                            !this.state?.handrailDetails?.filtersOptions
                              ?.offerOptions
                              ? [{ label: "Loading...", id: 0 }]
                              : this.state?.handrailDetails?.filtersOptions
                                  ?.offerOptions
                          }
                          onChange={async (option, value) => {
                            await this.setState({
                              handrailDetails: {
                                ...this.state?.handrailDetails,
                                tableLoader: true,
                                filters: {
                                  ...this.state?.handrailDetails?.filters,
                                  offer: {
                                    label: value?.label,
                                    value: value ? value?.value : "",
                                  },
                                },
                              },
                            });
                            this.getHandrailDataOnchange();
                          }}
                          value={this.state?.filters?.offer?.label || ""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={i18n.t("mandate_number")}
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>

          <HandrailsTable
            data={this.state?.handrailDetails}
            onAction={this.handleTableAction}
            tableHeaders={TABLE_HEAD}
          />
          <Backdrop
            sx={{
              color: "#e5e5e5",
              zIndex: (theme) => theme.zIndex.drawer + 1000,
            }}
            open={this.state?.loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Container>
      </>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <Dashboard {...props} navigate={navigate} params={params} />;
}
