const { default: i18n } = require("./i18n");

let ui_base_url = process.env.REACT_APP_UI_BASE_URL;
// if the url is complete, we don't need to add the current host, otherwise we add it
if (!ui_base_url?.includes("http")) {
  ui_base_url = window.location.origin + ui_base_url;
}

module.exports = {
  SERVER_BASE_URL: process.env.REACT_APP_BASE_URL,
  UI_BASE_URL: ui_base_url,
  APPLICATION_NAME: "BTEE",
  APPLICATION_FULL_NAME: "BTEE-AERO-GEST",
  version: "1.2.36",
  primaryColor: "#2065d1",
  primaryColorHighlighted: "#103996",
  toastDelay: 3000,
  tokenKey:"token",
  DATE_TIME_FORMAT: {
    TIME_FORMAT_12H: "LT",
  },
  enableEditOptionRenewal:['not_renewed'],
  tableLimitOptions: [
    { label: "10", value: 10 },
    { label: "25", value: 25 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ],

  statusOptions: [
    { label: i18n.t("in_progress"), value: "in_progress", color: "#59981A",textColor:'white' },
    { label: i18n.t("valid"), value: "valid", color: "#C1D9B7" },
    { label: i18n.t("declined"), value: "declined", color: "#BA0F30" ,textColor:'white'},
    { label: i18n.t("expired"), value: "expired", color: "black" ,textColor:'white' },
  ],
  categoriesOptions: [
    { label: "PPA", value: "PPA", color: "#68BBE3" },
    {
      label: i18n.t("crisis_management"),
      value: "crisis_management",
      color: "#FDD264",
    },
    { label: "SGS", value: "SGS", color: "#E7625F" },
    { label: "EISA", value: "EISA", color: "#5f88fb" ,textColor:'white'},
    { label: i18n.t("strategy"), value: "strategy", color: "#ffa500" },
    { label: i18n.t("others"), value: "others", color: "#A5A7CF" },
  ],
  domainOptions: [
    { label: "PPA", value: "PPA", color: "#68BBE3" ,
    subject:[
      {label:i18n.t('initial_training'),value:"initial_training"},
      {label:i18n.t('maintenance_and_improvement'),value:"maintenance_and_improvement"},
      {label:i18n.t('international_specialist_certificate'),value:"international_specialist_certificate"},
      {label:i18n.t('initial_campus_channel'),value:"initial_campus_channel"},
      {label:i18n.t('campus_refresh_channel'),value:"campus_refresh_channel"},
      {label:i18n.t('other'),value:"other"}
    ]},
    {
      label: i18n.t("crisis_management"),
      value: "crisis_management",
      color: "#FDD264",
      subject:[
        {label:i18n.t('crisis_management'),value:"crisis_management"},
        {label:i18n.t('emergency_plan'),value:"emergency_plan"},
        {label:i18n.t('other'),value:"other"}
    ]
    },
    { label: "SGS", value: "SGS", color: "#E7625F" ,
  subject:[
    {label:i18n.t('other'),value:"other"}
  ]},
    { label: i18n.t("other"), value: "other", color: "#A5A7CF",
    subject:[{
      label:i18n.t('other'),value:"other",
    }] },
  ],
  courseTypeOptions:[
    {label:i18n.t('individual_training'),value:"individual_training"},
    {label:i18n.t('collective_session_training'),value:"collective_session_training"},
    {label:i18n.t('airport_session_training'),value:"airport_session_training"}
  ],
  documentOptions: [
    { label: i18n.t("offer"), value: "offer", color: "#C1D9B7" },
    { label: i18n.t("mandate"), value: "mandate", color: "#C0C9CC" },
  ],
  roleOptions: [
    { label: i18n.t("proprietor"), value: "proprietor", internal: false },
    { label: i18n.t("operator"), value: "operator", internal: false },
    {
      label: i18n.t("director_general"),
      value: "director_general",
      internal: false,
    },
    {
      label: i18n.t("operations_director"),
      value: "operations_director",
      internal: false,
    },
    { label: i18n.t("chief_s_slia"), value: "chief_s_slia", internal: false },
    { label: i18n.t("chief_sppa_spra"), value: "chief_sppa", internal: false },
    { label: i18n.t("sppa_spra_representative"), value: "sppa_spra_representative", internal: false },
    { label: i18n.t("sgs_manager"), value: "sgs_manager", internal: false },
    { label: i18n.t("training_manager"), value: "training_manager", internal: false },
    { label: i18n.t("other"), value: "other", internal: false },
    { label: i18n.t("employee"), value: "employee", internal: true },
    { label: i18n.t("admin"), value: "admin", internal: true },
  ],
  confirmOptions: [
    { label: i18n.t("yes"), value: "yes", color: "#04aa6d" },
    { label: i18n.t("no"), value: "no", color: "#BA0F30" },
  ],
  respOptions: [
    { label: "SPI", value: "SPI", color: "#04aa6d" },
    { label: "GLO", value: "GLO", color: "#0a6ed1" },
    { label: "SLE", value: "SLE", color: "#feb13f" },
  ],
  bteeSaRepresentativeOptions: [
    { label: "SPI", value: "SPI", color: "#04aa6d" },
    { label: "GLO", value: "GLO", color: "#0a6ed1" },
    { label: "SLE", value: "SLE", color: "#feb13f" },
  ],
  expAirManagerOptions: [
    { label: "SPI", value: "SPI", color: "#04aa6d" },
    { label: "GLO", value: "GLO", color: "#0a6ed1" },
    { label: "SLE", value: "SLE", color: "#feb13f" },
  ],
  divisionOptions: [
    { label: "AERO", value: "AERO", color: "#595E60" ,textColor:"white"},
    { label: "AIRTRACE", value: "AIRTRACE", color: "#B0B7C0" },
  ],
  tradesOptions: [
    { label:  i18n.t("mail"), value: "mail"},
    { label:  i18n.t("phone"), value: "phone"},
    { label:  i18n.t("specialized_publication"), value: "specialized_publication"},
    { label:  i18n.t("others"), value: "others"},
  ],
  importanceOptions: [
    { label:  i18n.t("high"), value: "high",color: "red",textColor:"white"},
    { label:  i18n.t("medium"), value: "medium",color: "orange",textColor:"white"},
    { label:  i18n.t("low"), value: "low",color: "#FFFF80"},
  ],
  renewalCheckOptions:[
    { label:  i18n.t("renewed"), value: "renewed"},
    { label:  i18n.t("not_renewed"), value: "not_renewed"}
  ],
  renewalOptions: [
    { label:  i18n.t("none"), value: "none",color: "#7a86a2",textColor:"white"},
    { label:  i18n.t("written"), value: "written",color: "#b5a2ed",textColor:"white"},
    { label:  i18n.t("auto_renewal"), value: "auto_renewal",color: "#bae3ba",textColor:"white"}
  ],
  contractDurationOptions:[
    { label:  `1 ${i18n.t("year")}`, value: "1",color: "#df414a",textColor:"white" },
    { label:  `2 ${i18n.t("years")}`, value: "2",color: "#fbe36f" },
    { label:  `3 ${i18n.t("years")}`, value: "3",color: "#acc2d8"},
    { label:  `4 ${i18n.t("years")}`, value: "4",color: "#57c4ce"},
    { label:  `5 ${i18n.t("years")}`, value: "5",color: "#cae8ff "},
  ],
  currencyOptions:[
    { label: 'CHF', value: 'CHF' },
    { label: '€', value: 'EUR' },
    { label: '$', value: 'USD' }
  ],
  airportJsonKeys : [
    "code_iata",
    "code_oaci",
    "country",
    "is_open",
    "airport",
  ],
};
